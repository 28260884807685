<template>
  <div class="account">
    <PageSubHeader :pageSubHeader="pageSubHeader" />

    <!-- <main>
      <div id="construction" class="txt-center txt-light_green" style="padding: 15% 0">
        <h1 class="construction_msg">
          <font-awesome-icon icon="hammer" />
          建置中...
          <font-awesome-icon icon="wrench" />
        </h1>
      </div>
    </main> -->

    <main>
      <section id="account_apply">
        <div class="w1300 layout_container">

          <!-- *** 內頁主標題 *** -->
          <div class="main_title_box">
            <router-link
              to="/account/search/points"
              class="main_title_btn main_title_btn-evaluate small"
              target="_blank"
            >
              積分查詢
            </router-link>
            <router-link
              to="/account/search/extension"
              class="main_title_btn main_title_btn-apply small"
              target="_blank"
            >
              展延申請查詢
            </router-link>
            <h3 class="main_title txt-bold">證書展延申請</h3>
            <div class="img_box">
              <img src="@/statics/img/index/icon_title2.svg" alt="">
            </div>
          </div>

          <div class="main_description_box">
            <h4 class="main_description_title txt-light_green txt-bold">說明</h4>
            <p class="mg-tb-10">
              本會『居家醫療醫學專科醫師』證書有效期限為六年，期滿每次展延期限為六年。
              申請專科醫師證書有效期限展延，應於專科醫師證書之有效期限六年內，
              參加符合本會專科醫師甄審委員會規定之學術活動或繼續教育課程積分總分達180個學分以上者方可辦理展延，
              其中甲類140點必須參加由居家醫療醫學會主辦之研討活動；乙類40點則由居家醫療醫學會協辦或認可之研討活動。
              逾期未達學分數者，需於1年內補修滿180學分方可換證，且補修學分不計入新證書有效期限。
              如有疑問請洽台灣居家醫療醫學會秘書處0988-206590。
            </p>
          </div>

          <!-- *** form area *** -->
          <div class="form_box">
            <form id="form_account_apply_extend" @submit.prevent>

              <!-- *** 基本資料 *** -->
              <div class="form_description">
                <div class="main_description_box mg-tb-10">
                  <h4 class="main_description_title txt-light_green txt-bold">基本資料</h4>
                </div>
              </div>
              <div class="form_interactive">
                <div class="half">
                  <div class="fixed_info w33">
                    <p class="fixed_info_title">姓名</p>
                    <p class="fixed_info_content">{{ fixedInfo.realname }}</p>
                  </div>
                  <div class="fixed_info w33">
                    <p class="fixed_info_title">會員編號</p>
                    <p class="fixed_info_content">{{ fixedInfo.memberNo }}</p>
                  </div>
                  <div class="fixed_info w33">
                    <p class="fixed_info_title">性別</p>
                    <p class="fixed_info_content">
                      {{ userGender(fixedInfo.gender, fixedInfo.id_number) }}
                    </p>
                  </div>
                </div>
                <div class="half">
                  <div class="fixed_info w50">
                    <p class="fixed_info_title">出生年月日</p>
                    <p class="fixed_info_content">{{ fixedInfo.birthday }}</p>
                  </div>
                  <div class="fixed_info w50">
                    <p class="fixed_info_title">身分證字號/居留證號碼</p>
                    <p class="fixed_info_content">{{ fixedInfo.id_number }}</p>
                  </div>
                </div>
                <TextInput
                  class="w50"
                  v-model.trim="user.phone"
                  :item="inputFormat.phoneInput"
                  :rules="[
                    val => !!val || '必填欄位！',
                    [/^09\d{8}$/, '手機格式錯誤']
                  ]"
                  :maxlength="10"
                  :disabled="isDisabled"
                />
                <div class="half">
                  <TextInput
                    class="w50"
                    v-model.trim="user.memo.office_tel"
                    :item="inputFormat.telePublicInput"
                    :maxlength="11"
                    :disabled="isDisabled"
                  />
                  <TextInput
                    class="w50"
                    v-model.trim="user.memo.tel"
                    :item="inputFormat.telInput"
                    :maxlength="11"
                    :disabled="isDisabled"
                  />
                </div>
              </div>

              <!-- *** 服務資料 *** -->
              <div class="form_description">
                <div class="main_description_box mg-tb-10">
                  <h4 class="main_description_title txt-light_green txt-bold">服務資料</h4>
                </div>
              </div>
              <div class="form_interactive">
                <TextInput
                  class="w50"
                  v-model.trim="user.jobs.department"
                  :item="inputFormat.departmentInput"
                  :rules="[
                    val => !!val || '必填欄位！',
                    val => val.length > 1 || '長度錯誤!',
                  ]"
                  :disabled="isDisabled"
                />
                <TextInput
                  class="w50"
                  v-model.trim="user.jobs.hospital_name"
                  :item="inputFormat.hospitalInput"
                  :rules="[
                    val => !!val || '必填欄位！',
                    val => val.length > 1 || '長度錯誤!',
                  ]"
                  :disabled="isDisabled"
                />
                <Address
                  class="w100"
                  :title="'院所地址'"
                  :city.sync="user.jobs.hospital_city"
                  :area.sync="user.jobs.hospital_area"
                  :zipcode.sync="user.jobs.hospital_zipcode"
                  :addr.sync="user.jobs.hospital_addr"
                  :disabled="isDisabled"
                />
              </div>

              <!-- *** 展延事由 *** -->
              <div class="form_description">
                <div class="main_description_box mg-tb-10">
                  <h4 class="main_description_title txt-light_green txt-bold">展延事由</h4>
                </div>
              </div>
              <div class="form_interactive">
                <Textarea
                  class="w100"
                  :cols="30"
                  :rows="10"
                  v-model="user.memo.extend_content"
                  :item="inputFormat.postponeReasonTextarea"
                  :rules="[
                    val => !!val || '請輸入展延事由!',
                  ]"
                  :disabled="isDisabled"
                />
                <Upload
                  class="w100"
                  :class="{ disabled: isDisabled }"
                  v-model="user.files.extend_file.file"
                  :item="inputFormat.extensionProofUpload"
                  :rules="[
                    val => !!val || '請上傳檔案!',
                  ]"
                  :fileTitle.sync="user.files.extend_file.title"
                />
              </div>

              <div class="form_submit txt-center">
                <button
                  type="button"
                  class="btn p"
                  :class="{ disabled: isDisabled }"
                  @click="onSubmit"
                >送出</button>
              </div>

            </form>
          </div>

        </div>
      </section>
    </main>

  </div>
</template>

<script>
/** Libraries */
// eslint-disable-next-line no-unused-vars
import _ from 'lodash';
// eslint-disable-next-line no-unused-vars
import moment from 'moment';

/** Components */
import PageSubHeader from '@/components/PageSubHeader.vue';
import TextInput from '@/components/form/TextInput.vue';
import Textarea from '@/components/form/Textarea.vue';
import Address from '@/components/form/Address.vue';
import Upload from '@/components/form/Upload.vue';

/** Functions */
import { mapActions } from 'vuex';
import { AccountApplySubHeader } from '@/lib/const';
// eslint-disable-next-line no-unused-vars
import { userInfo, specialistExtend } from '@/lib/http';
// eslint-disable-next-line no-unused-vars
import { userGender, todayValid } from '@/lib/public';

export default {
  name: 'AccountApplyExtension',
  data() {
    return {
      /** Static Data */
      ...AccountApplySubHeader,
      isDisabled: true,
      inputFormat: {
        phoneInput: {
          label: '手機號碼',
          type: 'text',
          placeholder: '請輸入手機號碼',
          required: true,
        },
        telePublicInput: {
          label: '電話(公)',
          type: 'text',
          placeholder: '0212345678',
          required: false,
        },
        telInput: {
          label: '電話(家)',
          type: 'text',
          placeholder: '0212345678',
          required: false,
        },
        departmentInput: {
          label: '科別',
          type: 'text',
          placeholder: '請輸入完整名稱',
          required: true,
        },
        hospitalInput: {
          label: '院所名稱(例如：台灣居家醫療醫學會)',
          type: 'text',
          placeholder: '請輸入完整名稱',
          required: true,
        },
        postponeReasonTextarea: {
          placeholder: '請說明展延事由...',
          required: true,
        },
        extensionProofUpload: {
          label: '證明文件',
          reminder: '檔案大小限制：4MB；檔案類型：zip、doc、docx、pdf',
          accept: '.zip,.doc,.docx,.pdf',
          size: 4 * 1000 * 1000,
          required: true,
        },
      },

      /** Form Data */
      user: {
        phone: '',
        memo: {
          tel: '',
          office_tel: '',
          // title: '',
          extend_content: '',
        },
        jobs: {
          department: '',
          hospital_name: '',
          hospital_city: '',
          hospital_area: '',
          hospital_zipcode: '',
          hospital_addr: '',
        },
        files: {
          extend_file: {
            title: '',
            file: '',
          },
        },
      },

      /** Axios Data */
      fixedInfo: {
        realname: '',
        memberNo: '',
        gender: '',
        birthday: '',
        id_number: '',
      },
      userLevel: undefined,
      userStatus: undefined,
      maEndDate: undefined,
      maExtend: undefined,
    };
  },
  created() {
    userInfo().then((result) => {
      this.fixedInfo.realname = _.get(result, 'realname', '');
      this.fixedInfo.memberNo = _.get(result, 'no', '');
      this.fixedInfo.gender = _.get(result, 'gender', '');
      this.fixedInfo.birthday = _.get(result, 'birthday', '');
      this.fixedInfo.id_number = _.get(result, 'id_number', '');
      this.user.phone = _.get(result, 'phone', '');
      this.user.memo.tel = _.get(result, 'tel', '');
      this.user.memo.office_tel = _.get(result, 'memo.office_tel', '');
      this.user.jobs.department = _.get(result, 'jobs.department', '');
      this.user.jobs.hospital_name = _.get(result, 'jobs.hospital_name', '');
      this.user.jobs.hospital_city = _.get(result, 'jobs.hospital_city', '');
      this.user.jobs.hospital_area = _.get(result, 'jobs.hospital_area', '');
      this.user.jobs.hospital_zipcode = _.get(result, 'jobs.hospital_zipcode', '');
      this.user.jobs.hospital_addr = _.get(result, 'jobs.hospital_addr', '');
      this.userLevel = _.get(result, 'level', null);
      this.userStatus = _.get(result, 'user_status', null);
      this.maEndDate = _.get(result, 'ma_end_date', null);
      this.maExtend = _.get(result, 'ma_extend', null);
    }).then(() => {
      /** 確認使用者是否為專科醫師 */
      if (parseInt(this.userLevel, 10) === 1) {
        /** 確認使用者是否因為繳長年會費被停權 */
        if (parseInt(this.userStatus, 10) === 1) {
          /** 確認使用者是否曾經申請過展延 */
          if (!this.maExtend) {
            const applyStart = moment(this.maEndDate).subtract(21, 'days').format('YYYY-MM-DD');
            const applyEnd = this.maEndDate;
            /** 確認目前時間是否在到期日前三週 */
            if (todayValid(applyStart, applyEnd)) {
              this.isDisabled = false;
            } else {
              this.openModal({
                message: `
                  尚未在有效申請期間，請於<br>
                  <span class="txt-light_green">${applyStart}
                  </span> ~ <span class="txt-light_green">${applyEnd}</span>
                  <br>間提出申請
                `,
              });
              this.isDisabled = true;
              // this.isDisabled = false;
            }
          } else {
            this.openModal({
              message: '您已通過展延申請，請於展延期間內補修滿剩餘學分',
            });
            this.isDisabled = true;
          }
        } else {
          this.openModal({
            message: '您的會員資格已被停權，請洽學會了解更多',
          });
          this.isDisabled = true;
        }
      } else {
        this.openModal({
          message: '資格不符，您尚未申請成為專科醫師',
        });
        this.isDisabled = true;
      }
    });
  },
  methods: {
    ...mapActions([
      'openModal',
    ]),
    onSubmit() {
      const errorList = [];
      this.$children.forEach((item) => {
        if (item.$options.name && item.$options.name.match(/^Form/i)) {
          item.test();
          errorList.push(item.error);
        }
      });
      if (errorList.indexOf(true) === -1) {
        specialistExtend(this.user).then((result) => {
          const resMsg = _.get(result, 'data.message', '');
          if (result.data.status) {
            this.openModal({
              message: '展延申請已送出',
              url: '/account/search/extension',
            });
          } else {
            this.openModal({
              message: resMsg,
            });
          }
        });
      } else {
        this.openModal({
          message: '必填欄位尚未完成！',
        });
      }
    },
    userGender,
  },
  components: {
    PageSubHeader,
    // eslint-disable-next-line vue/no-unused-components
    TextInput,
    // eslint-disable-next-line vue/no-unused-components
    Textarea,
    // eslint-disable-next-line vue/no-unused-components
    Address,
    // eslint-disable-next-line vue/no-unused-components
    Upload,
  },
};
</script>
